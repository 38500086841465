/* EventCalendar.css */
.rbc-event {
    cursor: pointer; /* Make events look clickable */
  }
  
  .rbc-event-label {
    display: none; /* Hide default labels for clarity */
  }
  
  .toolbar-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }
  
  .button {
    margin: 0 5px;
    padding: 5px 10px;
    background-color: #3174ad;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .button:hover {
    background-color: #25597f;
  }
  
  .toolbar-label {
    margin: 0 20px;
    font-size: 1.2em;
    font-weight: bold;
  }
  